<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Manage Users</h3>

    <p>This guide explains how <span v-html="app_name"/> System Admins and Framework Admins can assign roles to expand user access beyond the basics described in our <span v-html="link('explorer', 'Standards Explorer')"></span> chapter.
        <ul>
            <li>A System Admin will navigate to <img srcset="/docimages/manage_users_icon.png 2.3x" class="k-help-img">, from the hamburger menu in the top right corner of their screen to create or edit user accounts and assign <span v-html="link('roles_system', 'system roles')"></span>.</li>

            <li>A Framework Admin will navigate to <img srcset="/docimages/manage_user_rights.png 2.3x" class="k-help-img">, from the kebab menu within an opened framework, to edit user accounts and assign <span v-html="link('roles_framework', 'framework roles')"></span>.</li>
        </ul>

    </p>

    <p>CREATE USER(S)
        <ul class="k-no-clear">
            <li>Begin by choosing the CREATE … USER button <img srcset="/docimages/create_new_user.png 3x" class="k-help-img"> or <img srcset="/docimages/add_framework_user.png 3x" class="k-help-img"> to manage access.
                <br>
                <br>
                <img srcset="/docimages/create_user.png 1.3x" class="k-help-img float-right">
            </li>
            <li>Enter the email address of the new user and choose ADD USER.</li>
            <li>Set up the user by completing the NAME fields, generating a RANDOM PASSWORD for first time sign-in, <img srcset="/docimages/random_password_icon.png 4x" class="k-help-img"> and choosing a ROLE (use the drop down menu). </li>
            <li>Select the CREATE USER AND SEND INVITATION EMAIL button.</li>
        </ul>
    </p>

    <p>The user will receive an email from <a href="contacts@commongoodlt.com">contacts@commongoodlt.com</a>
  within minutes (check junk or spam folder) and have 24 hours to click the one time sign in link and set up a new password. 
</p>
  <img srcset="/docimages/one_time_sign_in.png 1x" class="k-help-img block"/>

<p>If they miss the 24 hour window, they can click SIGN-IN and use the FORGOT PASSWORD option to generate another one time sign-in link.</p>

<br>

<p>EDIT USER(S)
    <p>From the <img srcset="/docimages/manage_users_icon.png 2.3x" class="k-help-img"> or <img srcset="/docimages/manage_user_rights.png 2.3x" class="k-help-img"> modal:
        <ul>
            <li>Begin by searching for the user by typing their first name, last name, or email.</li>
            
            <li>Use the <v-icon small>fas fa-edit</v-icon> EDIT icon to resend a one time sign in link, correct a typo, or change the role of a user.</li>
            <li>System Roles can be used in combination with framework roles. Framework Roles are assigned using the kebab menu  that can be found in the top right corner of the screen when viewing an open framework. See our <span v-html="link('roles_framework', 'Framework Roles User Guide')"></span> for more information. </li>
        </ul>
    </p>
</p>

<p>BEST PRACTICES FOR MANAGING USERS & FRAMEWORK REVIEW
    <p>Ideally, each Framework / System Admin would outline a process for framework review and editing. This may include:
        <ul>
            <li>Establishing a naming convention for frameworks (Editor / Admin)
                <ul>
                    <li>Example - Subject name (year adopted or implemented)
                        <ul>
                            <li>English Language Arts (2019)</li>
                        </ul>
                    </li>
                </ul>
                <li>Creating <span v-html="link('comment_groups', 'Comment Groups')"></span>  and verifying the access of needed members (Reviewer, Editor / Admin)</li>
                <li>Determining who will approve <span v-html="link('suggest_edits', 'suggested edits')"></span> (Editor / Admin)</li>
                <li><span v-html="link('archives', 'Stamping archives')"></span> during phases of the revision if needed (Editor / Admin)</li>
                <li>Setting an <span v-html="link('adoption_status', 'implementation date')"></span> for a public framework in draft or proposal (Editor / Admin)</li>
                <li>Deciding if a final review (Reviewer, Editor, or Admin) or step takes place for an adoption status to be updated (Editor / Admin)</li>
                <li>Creating categories (only available to System Admin) or using adoption status to identify the stage of the life cycle the framework is in (Editor / Admin)
                    <ul>
                        <li>Categories can help bundle frameworks by their life cycle: Current - In Review - No Longer in Use or Current - Previous - Proposed</li>
                        <ul>
                            <li>Georgia’s Standards - Current Versions</li>
                            <li>Georgia’s Standards - Previous Versions</li>
                        </ul>
                        <li>Categories can also help group frameworks for private or public viewing that may be used in reference or association to public frameworks</li>
                        <ul>
                            <li>State-funded Course Guide</li>
                            <li>Other Frameworks: WIDA, AP, ACT</li>
                        </ul>
                    </ul>
                </li>
                <li>Setting a retired date for a framework that will be deprecated (Editor / Admin)</li>
                <li>Duplicating an existing framework and/or creating a sandbox when a framework needs to be revised (Editor / Admin)</li>
            </li>
        </ul>
    </p>
</p>



</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>